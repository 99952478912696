import React from "react";
import "./landingPage.css";
import WelcomePart from "./welcomePart/welcomePart.jsx";
import Gallery2D from "./gallery2D/gallery2D.jsx";
import Artists from "./artists/artists.jsx";
import Arts from "./arts/arts.jsx";
import Gallery3D from "./gallery3D/gallery3D.jsx";
import brush1 from "../../images/brush1.png";
import brush2 from "../../images/brush2.png";
import brush3 from "../../images/brush3.png";
import brush4 from "../../images/brush4.png";
import VotePage from "../votePage/votePage.jsx";

const LandingPage = (props) => {
  return (
    <div className="landing">
      {props.activePopup !== 3 && (
        <>
          <WelcomePart />
          <Gallery2D />
          <Artists
            togglePopup={props.togglePopup}
            handleCurrentVideo={props.handleCurrentVideo}
          />
          <Arts
            togglePopup={props.togglePopup}
            handleCurrentArt={props.handleCurrentArt}
            handleVoteID={props.handleVoteID}
          />
          <Gallery3D />

          <img src={brush1} className="brush1" />
           <img src={brush2} className="brush2" /> 
          <img src={brush3} className="brush3" />
           <img src={brush4} className="brush4" /> 
        </>
      )}

      {props.activePopup === 3 && (
        <VotePage
          voteID={props.voteID}
          togglePopup={props.togglePopup}
          handleApproveMessage={props.handleApproveMessage}
        />
      )}
    </div>
  );
};

export default LandingPage;
