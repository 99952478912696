import React, { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import './App.css';
import LandingPage from "./components/landingPage/landingPage.jsx";
import ArtistPopup from "./components/artistPopup/artistPopup.jsx";
import ArtPopup from "./components/artPopup/artPopup.jsx";
import MainPopup from "./components/mainPopup/mainPopup.jsx";
import WinnerPopup from './components/winnerPopup/winnerPopup';
import Header from "./components/header/header.jsx";
import approveUser from "./API/approveUser.js";

function App() {

  const [activePopup, setActivePopup] = useState(0)
  const [currentVideo, setCurrentVideo] = useState("")
  const [currentArt, setCurrentArt] = useState({})
  const [voteID, setVoteID] = useState("")
  const [searchParams, setSearchParams] = useSearchParams();
  const [approveMessage, setApproveMessage] = useState("")
  const [seeWinner, setSeeWinner] = useState(false);

  const togglePopup = value => setActivePopup(value)
  const handleCurrentVideo = value => setCurrentVideo(value)
  const handleCurrentArt = value => setCurrentArt(value)
  const handleVoteID = value => setVoteID(value)
  const handleApproveMessage = value => setApproveMessage(value)

  const birraLocation = 'https://www.birramoretti.com/rs/sr/umetnost';


  useEffect(() => { // This will be shown when we announce winner for 2024
 //   if (!seeWinner) {   
 //     setActivePopup(5);
 //     setSeeWinner(!seeWinner);
 //   }
    const handleApproveUser = async () => {
      let response = await approveUser(searchParams.get("verificationToken"))
      if (response) {
        setActivePopup(4)
        if (response.error) {
          setApproveMessage(response.error.message)
        }
        else if (response.success) {
          setApproveMessage(response.success.message)
        }
        else {
          setApproveMessage("Trenutno imamo problema, pokušajte kasnije.")
        }
      }
    }
    if (searchParams.get("verificationToken")) {
      handleApproveUser()
    }
    // if (!window.location.href.includes(birraLocation)) {
    //   window.location.href = birraLocation;
    // }
  }, [1])

  console.log('Bogi ', activePopup)

  return (
    <div className="App">
      {(activePopup !== 3 && activePopup !== 1 && activePopup !== 2) &&
        <Header togglePopup={togglePopup}
          activePopup={activePopup} />
      }

      {(activePopup === 0 || activePopup === 3) &&
        <LandingPage togglePopup={togglePopup}
          handleCurrentVideo={handleCurrentVideo}
          activePopup={activePopup}
          handleCurrentArt={handleCurrentArt}
          voteID={voteID}
          handleVoteID={handleVoteID}
          handleApproveMessage={handleApproveMessage} />}

      {activePopup === 1 && <ArtistPopup togglePopup={togglePopup}
        currentVideo={currentVideo}
        handleCurrentVideo={handleCurrentVideo} />}

      {activePopup === 2 && <ArtPopup togglePopup={togglePopup}
        currentArt={currentArt}
        handleVoteID={handleVoteID}
      />}

      {activePopup === 4 && <MainPopup togglePopup={togglePopup}
        approveMessage={approveMessage} />}

      {activePopup === 5 && <WinnerPopup togglePopup={togglePopup} />}
    </div>

  );
}

export default App;
