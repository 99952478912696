const { api } = require('./index');
const fetchSingleArt = async (artId) => {
  const response = await fetch(`${api}/api/arts/${artId}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
  const result = response.json();
  return result;
}

export default fetchSingleArt;