import React from 'react';
import "./artist.css";
import playThumbnail from "../../../images/playThumbnail.png";

const Artist = props => {

  const handleClick = () => {
    props.handleCurrentVideo(props.profile)
    props.togglePopup(1)
  }

  return (
    <div className="artist">
      <div className="image" onClick={handleClick}>
        <img src={`https://api.iframe-umetnost-rs.birramoretti.com/2024/profile/${props.image}`} />
        <img src={playThumbnail} />
      </div>

      <div className="name">{props.firstName + " " + props.lastName}</div>

      <div className="academy">{props.faculty + ", " + props.city}</div>
    </div>
  )
};

export default Artist;
