import React from 'react';
import { Link } from 'react-scroll';
import "./header.css";

const Header = props => {

  const handleClick = name => {
    if (props.activePopup === 0) {
      document.getElementById(name).scrollIntoView({ behavior: "smooth" });
    }
    else {
      props.togglePopup(0)
      setTimeout(() => {
        document.getElementById(name).scrollIntoView({ behavior: "smooth" });
      }, 300);
    }

  }

  return (
    <header id="header">
      <div onClick={() => handleClick("section2")}>PUT U ITALIJU</div>
      <div onClick={() => handleClick("section3")}>UPOZNAJ UMETNIKE</div>
      <div onClick={() => handleClick("section4")}>GLASAJ</div>
      <div onClick={() => handleClick("section5")}>VIRTUELNA IZLOŽBA</div>
     {/*<a href="https://www.birramoretti.com/rs/sr/umetnost2023" target="_blank" >2023</a> */} 
    </header>
  )
};

export default Header;
