const { api } = require('./index');
const approveUser = async (verificationToken) => {
  const response = await fetch(`${api}/api/users/approve`, {
    method: 'POST',
    body: JSON.stringify({
      verificationToken
    }),
    headers: { 'Content-Type': 'application/json' },
  });
  const result = response.json();
  return result;
}

export default approveUser;