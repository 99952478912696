import React, { useState, useEffect } from 'react';
import "./artists.css";
import desktopLandingTitle from "../../../images/desktopLandingTitle.png";
import Artist from "./artist.jsx";
import fetchArtists from "../../../API/fetchArtists.js";

const Artists = props => {

  const [artists, setArtists] = useState([])

  useEffect(() => {
    const fetch = async () => {
      let response = await fetchArtists()
      if (response.success) {
        setArtists(response.success.data.artists)
      }
    }
    fetch()
  }, [1])

  return (
    <div className="artists">
      <div className="headTitle" id="section3">
        UPOZNAJTE UMETNIKE
        <img className="desktopLandingTitle" src={desktopLandingTitle} />
      </div>

      <div className="textSubtitle">
        Upoznajte naše mlade umetnike sa umetničkih fakulteta iz Srbije, koji će 
        vam predstaviti svoje radove i približiti na koji način ih je put u Italiju inspirisao.
      </div>

      <div className="content">
        {artists.map(artist => (
          <Artist key={artist.id}
            firstName={artist.firstName}
            lastName={artist.lastName}
            faculty={artist.faculty}
            city={artist.city}
            profile={artist.profileVideo}
            image={artist.profileImage}
            togglePopup={props.togglePopup}
            handleCurrentVideo={props.handleCurrentVideo}
          />
        ))}
      </div>
    </div>
  )
};

export default Artists;
