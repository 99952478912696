const { api } = require('./index');
const fetchArts = async () => {
  const response = await fetch(`${api}/api/arts`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
  const result = response.json();
  return result;
}

export default fetchArts;