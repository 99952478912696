import React from 'react';
import "./art.css";
import fetchSingleArt from "../../../API/fetchSingleArt";

const Art = props => {

  const handleClick = async () => {
    let response = await fetchSingleArt(props.ID)
    if (response.success) {
      props.handleCurrentArt(response.success.data)
      props.togglePopup(2)
    }
  }

  const handleVote = () => {
    props.handleVoteID(props.ID)
    props.togglePopup(3)
  }

  return (
    <div className="art" style={{ transform: `translateX(${props.transition}px` }}>
      <img src={`https://api.iframe-umetnost-rs.birramoretti.com/2024/arts/${props.image}`} className='artImg' onClick={handleClick} />
      <div className={props.firstName == 'Marta' || props.firstName == 'Lana' ? 'shortName' : "name"}>{props.firstName} <br/>  {props.lastName}</div>
      <div className="info">{props.name} <br /> {props.technique}</div>
      <div className="btn" onClick={handleVote}>GLASAJ</div>
    </div>
  )
};

export default Art;
