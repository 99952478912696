const { api } = require('./index');
const fetchArtists = async () => {
  const response = await fetch(`${api}/api/artists`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
  const result = response.json();
  return result;
}

export default fetchArtists;