import React, { useState, useEffect } from "react";
import "./arts.css";
import desktopLandingTitle from "../../../images/desktopLandingTitle.png";
import desktopLeftBtn from "../../../images/desktopLeftBtn.png";
import desktopRightBtn from "../../../images/desktopRightBtn.png";
import Art from "./art.jsx";
import fetchArts from "../../../API/fetchArts.js";

const Arts = (props) => {
  const [arts, setArts] = useState([]);
  const [transition, setTransition] = useState(0);

  const handleNext = () => {
    const element = document.getElementById("artScroller");
    if (element) {
      element.scrollBy(278, 0);
    }
  };

  const handlePrev = () => {
    const element = document.getElementById("artScroller");
    if (element) {
      element.scrollBy(-278, 0);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      let response = await fetchArts();
      if (response.success) {
        setArts(response.success.data.arts);
      }
    };
    fetch();
  }, [1]);

  return (
    <div className="arts">
      <div className="headTitle" id="section4">
        RADOVI UMETNIKA
        <img className="desktopLandingTitle" src={desktopLandingTitle} />
      </div>

      <div className="textSubtitle">
        Naši talentovani studenti su u Italiji pronašli inspiraciju i stvorili svoja nova umetnička dela.
        Pogledajte njihove radove i glasajte za rad koji je na vas ostavio najveći utisak.
        <br /> <br/>
        Glasanje za najbolji studentski rad traje od 13.05. do 14.07.2024. godine, nakon čega
        će na osnovu pristiglih glasova i glasova stručnog žirija biti utvrđen pobednik konkursa
        ,,Stvaramo umetnost inspirisani Italijom’’. Studenta sa najboljim radom očekuje sjajna nagrada.
      </div>

      <div className="content">
        <div src={desktopLeftBtn} className="images" id="artScroller">
          {arts.map((art) => (
            <Art
              key={art.artId}
              ID={art.artId}
              firstName={art.firstName}
              lastName={art.lastName}
              image={art.image}
              name={art.name}
              technique={art.artTechnique}
              transition={transition}
              togglePopup={props.togglePopup}
              handleCurrentArt={props.handleCurrentArt}
              handleVoteID={props.handleVoteID}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Arts;
